<template>
    <div>
        <form id="step">
            <b-steps v-model="activeStep" :mobile-mode="null" :has-navigation="false">
                <template v-for="(step, index) in data">
                    <b-step-item :key="index">
                        <div v-if="step.Type === 'form'">
                            <all-field :data="step.Data" />
                        </div>
                        <div v-else-if="step.Type === 'accordian'">
                            <accordian :data="step.Data" @payment="payment" />
                        </div>
                    </b-step-item>
                </template>
            </b-steps>
            <div v-if="activeStep === 0" class="mt-4 mb-4">
                <span class="font-bold">Note: Please note that the voucher would be sent to the contact email registered above, please make sure the email address is still active and can be accessed.</span>
            </div>
            <div class="flex w-full justify-end">
                <b-button type="is-primary" :disabled="activeStep === 0" @click="activeStep--" class="mr-2">BACK</b-button>
                <b-button type="is-primary" :disabled="activeStep === (data.length - 1)" @click="activeStep++, next()">NEXT</b-button>
            </div>
        </form>
    </div>
</template>
<script>
export default {
    components: {
        AllField: () => import('@/components/form/AllField'),
        Accordian: () => import('@/components/order/accordian')
    },
    props: {
        data: {
            type: Array
        }
    },
    data () {
        return {
            activeStep: 0,
            company: JSON.parse(localStorage.getItem('company'))
        }
    },
    methods: {
        next () {
            var data = this.getData('step')
            if (data.ContactEmail !== null && !data.ContactEmail.includes('@')) return this.activeStep--
            if (this.company && this.company.User.Name === 'elitefutsal1') if (data.ContactEmail === null || data.ContactName === null || data.ContactPhone === null) this.activeStep--
        },
        async payment () {
            var data = this.getData('step')
            await this.$baseApi({
                method: 'POST',
                url: '/booking/' + this.$route.query.Oid + '/pay',
                data: data
            })
            this.$router.push({
                path: '/item/havepaid?Oid=' + this.$route.query.Oid + '&idpay=' + data.PaymentMethod
            })
            // this.$router.push({
            //     path: '/item/success'
            // })
        }
    }
}
</script>
<style lang="scss" scoped>
    ::v-deep .step-content {
        padding: 0 !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
</style>
